import Vue from 'vue'
import VueRouter from 'vue-router'
import {appProd} from '@/config/env.dev'
import {ACCESS_CustomerUser, ACCESS_TOKEN} from "@/store/mutation-types";
import store from '@/store/index.js';
import {post} from '@/utils/http'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        redirect: 'login' //重定向
    },
    {
        path: '/testLogin',
        name: 'testLogin',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/testLogin.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/login/login.vue')
    },
    {
        path: '/index',
        name: 'index',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/index.vue')
    },
    {
        path: '/introduction',
        name: 'introduction',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/introduction.vue')
    },
    {
        path: '/nutrientSource',
        name: 'nutrientSource',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/nutrientSource.vue')
    },
    {
        path: '/source_detail',
        name: 'source_detail',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/source_detail.vue')
    },
    {
        path: '/switch',
        name: 'switch',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/login/switch.vue')
    },
    {
        path: '/switchSystem',
        name: 'switchSystem',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/switch_system.vue')
    },
    {
        path: '/bind_student',
        name: 'bind_student',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/login/bind_student.vue')
    },
    {
        path: '/bind_employ',
        name: 'bind_employ',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/login/bind_employ.vue')
    },
    {
        path: '/switch_user',
        name: 'switch_user',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/switch_user.vue')
    },
    {
        path: '/business_customer_user_free_amount',
        name: 'business_customer_user_free_amount',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/business_customer_user_free_amount.vue')
    },
    {
        path: '/transaction_log',
        name: 'transaction_log',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/transaction_log.vue')
    },
    {
        path: '/account_loose_detail',
        name: 'account_loose_detail',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/account_loose_detail.vue')
    },
    {
        path: '/cashout',
        name: 'cashout',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/cashout.vue')
    },
    {
        path: '/recharge/cashoutSuccess',
        name: 'rechargeCashoutSuccess',
        meta: {
            title: '提现结果',
            icon: ''
        },
        component: () => import('../views/recharge/cashout_success.vue')
    },
    {
        path: '/recharge',
        name: 'recharge',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/recharge.vue')
    },
    {
        path: '/recharge/rechargeSuccess',
        name: 'rechargeRechargeSuccess',
        meta: {
            title: '充值结果',
            icon: ''
        },
        component: () => import('../views/recharge/recharge_success.vue')
    },
    {
        path: '/bundle_calendar',
        name: 'bundle_calendar',
        meta: {
            title: '报停餐',
            icon: ''
        },
        component: () => import('../views/stopchangmeals/bundle_calendar.vue')
    },
    {
        path: '/bundleDetail',
        name: 'bundle_detail',
        meta: {
            title: '餐费包详情',
            icon: ''
        },
        component: () => import('../views/stopchangmeals/bundle_detail.vue')
    },
    {
        path: '/reservation_enroll',
        name: 'reservation_enroll',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/stopchangmeals/reservation_enroll.vue')
    },
    {
        path: '/reservation_stop',
        name: 'reservation_stop',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/stopchangmeals/reservation_stop.vue')
    },
    {
        path: '/reservation_change',
        name: 'reservation_change',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/stopchangmeals/reservation_change.vue')
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/accountsettings/About.vue')
    },
    {
        path: '/about_user',
        name: 'about_user',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/accountsettings/About_user.vue')
    },
    {
        path: '/about_us',
        name: 'about_us',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/accountsettings/About_us.vue')
    },
    {
        path: '/bundle_tody',
        name: 'bundle_tody',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/bundle_tody.vue')
    },
    {
        path: '/share',
        name: 'share',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/share.vue')
    },
    {
        path: '/bankInfo',
        name: 'bankInfo',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/bank/bank_info.vue')
    },
    {
        path: '/bankChangeNo',
        name: 'bankChangeNo',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/accountsettings/bank_change_no.vue')
    },
    {
        path: '/bankSms',
        name: 'bankSms',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/bank/bank_sms.vue')
    },
    {
        path: '/bankSwitch',
        name: 'bankSwitch',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/bank/bank_switch.vue')
    },
    {
        path: '/bankPassword',
        name: 'bankPassword',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/bank/bank_password.vue')
    },
    {
        path: '/search',
        name: 'search',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/search.vue')
    },
    {
        path: '/bankSteps',
        name: 'bankSteps',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/bank/bank_steps.vue')
    },
    {
        path: '/feedback',
        name: 'feedback',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/feedback.vue')
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/change_password.vue')
    },
    {
        path: '/flowDetails',
        name: 'flowDetails',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/flow_details.vue')
    },
    {
        path: '/pushDetail',
        name: 'pushDetail',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/push/push_detail.vue')
    },
    {
        path: '/verificationPushDetail',
        name: 'verificationPushDetail',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/push/VerificationPush_detail.vue')
    },
    {
        path: '/verification',
        name: 'verification',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/old/verification.vue')
    },
    {
        path: '/indexWeekMenus',
        name: 'indexWeekMenus',
        meta: {
            title: '一周菜单',
            icon: ''
        },
        component: () => import('../views/index/index_week_menus.vue')
    },
    {
        path: '/AccountAgreement',
        name: 'AccountAgreement',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/account/AccountAgreement.vue')
    },
    {
        path: '/AccountThreeTypeInfo',
        name: 'AccountThreeTypeInfo',
        meta: {
            title: '开通电子钱包',
            icon: ''
        },
        component: () => import('../views/account/AccountThreeTypeInfo.vue')
    },
    {
        path: '/AccountCreateFeedback',
        name: 'AccountCreateFeedback',
        meta: {
            title: '结果',
            icon: ''
        },
        component: () => import('../views/account/AccountCreateFeedback.vue')
    },
    {
        path: '/AccountUpgradeDescription',
        name: 'AccountUpgradeDescription',
        meta: {
            title: '账户升级介绍',
            icon: ''
        },
        component: () => import('../views/account/AccountUpgradeDescription.vue')
    },
    {
        path: '/AccountUpgrade',
        name: 'AccountUpgrade',
        meta: {
            title: '账户升级',
            icon: ''
        },
        component: () => import('../views/account/AccountUpgrade.vue')
    },
    {
        path: '/AccountUpgradeResubmitting',
        name: 'AccountUpgradeResubmitting',
        meta: {
            title: '账户升级重新提交',
            icon: ''
        },
        component: () => import('../views/account/AccountUpgradeResubmitting.vue')
    },
    {
        path: '/DealResult',
        name: 'DealResult',
        meta: {
            title: '交易结果',
            icon: ''
        },
        component: () => import('../views/deal/DealResult')
    },

    {
        path: '/kitchen/alarmDetail',
        name: 'kitchenAlarmDetail',
        meta: {
            title: '报警详情',
            icon: ''
        },
        component: () => import('../views/kitchen/AlarmDetail.vue')
    },
    {
        path: '/warningDetailWeigh',
        name: 'warningDetailWeigh',
        meta: {
            title: '称重报警详情',
            icon: ''
        },
        component: () => import('../views/temp/WarningDetailWeigh.vue')
    },
    {
        path: '/kitchen/login',
        name: 'kitchenLogin',
        meta: {
            title: '登录',
            icon: ''
        },
        component: () => import('../views/kitchen/login')
    },
    {
        path: '/kitchen/index',
        name: 'kitchenIndex',
        meta: {
            title: '首页',
            icon: ''
        },
        component: () => import('../views/kitchen/index')
    },
    {
        path: '/kitchen/userList',
        name: 'kitchenUserList',
        meta: {
            title: '用户管理',
            icon: ''
        },
        component: () => import('../views/kitchen/UserList')
    },
    {
        path: '/kitchen/cameraList',
        name: 'kitchenCameraList',
        meta: {
            title: '安装区',
            icon: ''
        },
        component: () => import('../views/kitchen/CameraList')
    },
    {
        path: '/kitchen/alarmList',
        name: 'kitchenAlarmList',
        meta: {
            title: '报警列表',
            icon: ''
        },
        component: () => import('../views/kitchen/AlarmList')
    },
    {
        path: '/kitchen/alarmSetting',
        name: 'kitchenAlarmSetting',
        meta: {
            title: '报警设置',
            icon: ''
        },
        component: () => import('../views/kitchen/AlarmSetting')
    },
    {
        path: '/kitchen/alarmDefenseSetting',
        name: 'kitchenAlarmDefenseSetting',
        meta: {
            title: '报警布防设置',
            icon: ''
        },
        component: () => import('../views/kitchen/AlarmDefenseSetting')
    },

    {
        path: '/tuition/tuitionAndFeesList',
        name: 'tuitionAndFeesList',
        meta: {
            title: '学杂费交收',
            icon: ''
        },
        component: () => import('../views/tuition/TuitionAndFeesList')
    },
    {
        path: '/tuition/tuitionAndFeesDetail',
        name: 'tuitionAndFeesDetail',
        meta: {
            title: '学杂费交收详情',
            icon: ''
        },
        component: () => import('../views/tuition/TuitionAndFeesDetail')
    },
    {
        path: '/tuition/TuitionAndFeesOrderConfirm',
        name: 'TuitionAndFeesOrderConfirm',
        meta: {
            title: '学杂费交收确认',
            icon: ''
        },
        component: () => import('../views/tuition/TuitionAndFeesOrderConfirm')
    },
    {
        path: '/reservationRecordConfirmList',
        name: 'reservationRecordConfirmList',
        meta: {
            title: '我的停餐确认',
            icon: ''
        },
        component: () => import('../views/stopchangmeals/reservation_record_confirm_list')
    },
    {
        path: '/reservationRecordConfirm',
        name: 'reservationRecordConfirm',
        meta: {
            title: '停餐确认',
            icon: '',
        },
        component: () => import('../views/stopchangmeals/reservation_record_confirm')
    },
    {
        path: '/reservationRecordResult',
        name: 'reservationRecordResult',
        meta: {
            title: '停餐确认',
            icon: '',
        },
        component: () => import('../views/stopchangmeals/reservation_record_result')
    },
    {
        path: '/reservationRecordConfirmDissent',
        name: 'reservationRecordConfirmDissent',
        meta: {
            title: '提出异议',
            icon: '',
        },
        component: () => import('../views/stopchangmeals/reservation_record_confirm_dissent')
    },

    {
        path: '/pb/calendar',
        name: 'productBundleCalendar',
        meta: {
            title: '日历',
            icon: '',
        },
        component: () => import('../views/old/product_bundle_calendar')
    },
    {
        path: '/recharge/newspapermeals',
        name: 'rechargeNewspaperMeals',
        meta: {
            title: '报餐',
            icon: '',
        },
        component: () => import('../views/recharge/newspapermeals')
    },
    {
        path: '/recharge/applicationList',
        name: 'rechargeApplicationList',
        meta: {
            title: '报餐结果',
            icon: '',
        },
        component: () => import('../views/recharge/applicationList')
    },
    {
        path: '/recharge/applyRechargeList',
        name: 'rechargeApplyRechargeList',
        meta: {
            title: '缴费',
            icon: '',
            // keepAlive: true
        },
        component: () => import('../views/recharge/apply_recharge_list')
    },
    {
        path: '/recharge/history',
        name: 'rechargeHistory',
        meta: {
            title: '缴费历史',
            icon: '',
            // keepAlive: true
        },
        component: () => import('../views/recharge/history')
    },
    {
        path: '/recharge/applyRechargeDetail',
        name: 'rechargeApplyRechargeDetail',
        meta: {
            title: '餐费包详情',
            icon: '',
        },
        component: () => import('../views/recharge/apply_recharge_detail')
    },
    {
        path: '/recharge/confirmRecharge',
        name: 'rechargeConfirmRecharge',
        meta: {
            title: '确认缴费',
            icon: '',
        },
        component: () => import('../views/recharge/confirm_recharge')
    },
    {
        path: '/recharge/applyRechargeResult',
        name: 'rechargeApplyRechargeResult',
        meta: {
            title: '报名结果',
            icon: '',
        },
        component: () => import('../views/recharge/apply_recharge_result')
    },

    {
        path: '/account/detail',
        name: 'accountDetail',
        meta: {
            title: '账户',
            icon: '',
            // keepAlive: true
        },
        component: () => import('../views/account/account_detail')
    },
    {
        path: '/recharge/freedom',
        name: 'rechargeFreedom',
        meta: {
            title: '零钱充值',
            icon: '',
        },
        component: () => import('../views/recharge/recharge_freedom')
    },

    // 我的工作
    {
        path: '/my/work',
        name: 'myWork',
        meta: {
            title: '我的工作',
            icon: ''
        },
        component: () => import('../views/work/MyWork')
    },
    // 预订加餐

    // {
    //     path: '/AdditionalMeals/index',
    //     name: 'index',
    //     meta: {
    //         title: '预订加餐首页',
    //         icon: ''
    //     },
    //     component: () => import('../views/AdditionalMeals/index.vue')
    // },
    // {
    //     path: '/AdditionalMeals/cart',
    //     name: 'cart',
    //     meta: {
    //         title: '预订加餐购物车',
    //         icon: ''
    //     },
    //     component: () => import('../views/AdditionalMeals/cart.vue')
    // },
    // {
    //     path: '/AdditionalMeals/user',
    //     name: 'user',
    //     meta: {
    //         title: '预订加餐我的',
    //         icon: ''
    //     },
    //     component: () => import('../views/AdditionalMeals/user.vue')
    // },

    // 预定加餐（新）
    {
        path: '/bookMeal/home',
        name: 'home',
        meta: {
            title: '预订加餐首页',
            icon: ''
        },
        component: () => import('../views/bookMeal/Home.vue')
    },
    {
        path: '/product/detail',
        name: 'ProductDetail',
        meta: {
            title: '商品详情',
            icon: ''
        },
        component: () => import('../views/bookMeal/ProductDetail')
    },
    {
        path: '/mine/recharge/freedom',
        name: 'MineRechargeFreedom',
        meta: {
            title: '充值',
            icon: ''
        },
        component: () => import('../views/bookMeal/recharge_freedom')
    },
    {
        path: '/mine/withdraw/freedom',
        name: 'MineWithdrawFreedom',
        meta: {
            title: '提现',
            icon: ''
        },
        component: () => import('../views/bookMeal/WithdrawFreedom.vue')
    },
    {
        path: '/cart/order-confirm',
        name: 'CartOrderConfirm',
        meta: {
            title: '提交订单',
            icon: ''
        },
        component: () => import('../views/bookMeal/OrderConfirm.vue')
    },
    {
        path: '/bookMeal/order-list',
        name: 'MineOrderList',
        meta: {
            title: '我的订单',
            icon: '',
        },
        component: () => import('../views/bookMeal/OrderList.vue')
    },
    {
        path: '/bookMeal/transaction-log',
        name: 'BookMealTransactionLog',
        meta: {
            title: '交易流水',
            icon: ''
        },
        component: () => import('../views/bookMeal/TransactionLog')
    },


    {
        path: '/my/work/detail',
        name: 'myWorkDetail',

        meta: {
            title: '待核销名单',
            icon: ''
        },
        component: () => import('../views/work/MyWorkDetail')
    },

    // 废弃
    {
        path: '/customer_user_account',
        name: 'customer_user_account',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/recharge/customer_user_account.vue')
    },

    // 广发3500活动
    {
        path: '/activity/guangfa3500',
        name: 'activityGuangfa3500',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/activity/guangfa3500.vue')
    },
    // 新链接二维码
    {
        path: '/index/qrcode',
        name: 'indexQrcode',
        meta: {
            title: appProd === 'production' ? '一天膳事®安心食堂智慧云平台' :
                (appProd === 'production-zsy' ? '众膳云®安心食堂智慧云平台' :
                    (appProd === 'dev-zsy' ? '众膳云®安心食堂智慧云平台' : '一天膳事®安心食堂智慧云平台')),
            icon: ''
        },
        component: () => import('../views/index/qrcode.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
// router.beforeEach((to, from, next) => {
//     store.commit('changeloading', true);
//     let accessToken = Vue.ls.get(ACCESS_TOKEN);
//     if (!accessToken) {
//         store.commit('changeloading', false);
//         next()
//         return;
//     }

//     // production-zsy
//     // production
//     if (to.name === 'indexQrcode'||to.name=='verificationPushDetail'||to.name=='pushDetail') {
//         console.log(1);
//         store.commit('changeloading', false);
//         next()
//         return
//     }
//     let customerUser = Vue.ls.get(ACCESS_CustomerUser);
//     if (!customerUser) {
//         store.commit('changeloading', false);
//         if (from.name === 'switch') {
//             return;
//         }
//         next();
//         return;
//     }

//     post("api/customer/user/v2/search", {
//         page: 0,
//         size: 500
//     }).then((res) => {
//         if (res.data.rows.length <= 0) {
//             // 未绑定过消费者的用户，跳转去绑定身份
//             router.push({path: "/switch"});
//         }

//         let rows = [];
//         if (appProd === 'production-zsy') {
//             // 众膳云环境
//             res.data.rows.forEach(customerUser => {
//                 if (customerUser.businessCustomerUser.business.attributionPlatform.includes('ZSY')) {
//                     // 不包含，检查其他消费者是否包含
//                     rows.push(customerUser)
//                 }
//             })

//         } else {
//             rows = res.data.rows;
//         }
//         if (rows.length <= 0) {
//             // 未绑定过消费者的用户，跳转去绑定身份
//             router.push({path: "/switch"});
//         }

//         let find = rows.find(
//             (item) => item.id === customerUser.id
//         );
//         if (!find) {
//             // 如果关系已解除，则取第一个当成默认
//             customerUser = rows[0];
//         } else {
//             customerUser = find;
//         }

//         Vue.ls.set(
//             ACCESS_CustomerUser,
//             customerUser,
//             7 * 24 * 60 * 60 * 1000
//         );

//         // 检查链接
//         let url_zsy = 'zhongshanyun.cn';
//         let url_ytss = 'fsytss.com'

//         // 当前链接
//         let url = window.location.href
//         // 当前平台
//         let attributionPlatform = null
//         if (url.includes(url_zsy)) {
//             attributionPlatform = 'ZSY'
//         } else if (url.includes(url_ytss)) {
//             attributionPlatform = 'YTSS'
//         }
//         // 看当前消费者是否符合当前所示平台
//         if (customerUser.businessCustomerUser.business.attributionPlatform.includes(attributionPlatform)) {
//             store.commit('changeloading', false);
//             next()
//         } else {
//             store.commit('changeloading', false);
//             router.push(`/index/qrcode?url=${customerUser.businessCustomerUser.business.attributionPlatform}`)
//         }

//     }).catch(err => {
//         if (err.status === 1004) {
//             Vue.ls.set(ACCESS_TOKEN, null);
//         }
//         next();
//     });

// })
export default router
