import Vue from 'vue'
import Vuex from 'vuex'
import addMeal from './addMeal/index';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading2:false
  },
  mutations: {
    changeloading(state,loading2){
      state.loading2=loading2
    }
  },
  actions: {
  },
  modules: {
    addMeal
  }
})
