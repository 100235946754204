export default {
    namespaced:true,
    state:{
        // 此项为预订加餐首页底部tabbar的标识
        tabbarActive:0,
        // 此项为用户选择的日期
        dateActive:'',
        // 用户选择的餐次
        mealActive:''
    },
    mutations:{
        changeTabbarActive(state,payload){
            state.tabbarActive=payload
        },
        changeDateActive(state,payload){
            state.dateActive=payload
        },
        changeMealActive(state,payload){
            state.mealActive=payload
        }
    }
}