import {cutOutNum} from "./decimals";

export function timeFix() {
    const time = new Date()
    const hour = time.getHours()
    return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
    const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
    const index = Math.floor(Math.random() * arr.length)
    return arr[index]
}

export function formatTime(time, option) {
    // time = +time * 1000
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000;
    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 365) {
        return Math.ceil(diff / 3600 / 24) + '天前'
    } else if (diff < 3600 * 24 * 365 * 100) {
        return Math.ceil(diff / 3600 / 365 / 24) + '年前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
    const event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
    let timer = 0

    let beforeScrollTop = window.pageYOffset
    callback = callback || function () {
    }
    window.addEventListener(
        'scroll',
        event => {
            clearTimeout(timer)
            timer = setTimeout(() => {
                let direction = 'up'
                const afterScrollTop = window.pageYOffset
                const delta = afterScrollTop - beforeScrollTop
                if (delta === 0) {
                    return false
                }
                direction = delta > 0 ? 'down' : 'up'
                callback(direction)
                beforeScrollTop = afterScrollTop
            }, 50)
        },
        false
    )
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
    if (id === '') {
        return
    }
    setTimeout(() => {
        document.body.removeChild(document.getElementById(id))
    }, timeout)
}

export function guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0,
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function formatDate(dateStr) {
    var newdateStr = dateStr.replace(/\d+(?=-[^-]+$)/, (a) => (parseInt(a, 10) - 1));  //字符串处理(月份)
    var nums = newdateStr.match(/\d+/g);  //取数
    var date = eval('new Date(' + nums + ')');  //转换
    //重组
    var datetime =
        date.getFullYear() + "-" +  //年
        ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1)) + "-" +  //月
        (date.getDate() > 9 ? date.getDate() : ("0" + date.getDate()));  //日
    return datetime;
}

export function getRealLength(str) {
    var realLength = 0, len = str.length, charCode = -1;
    for (var i = 0; i < len; i++) {
        charCode = str.charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) realLength += 1;
        else realLength += 2;
    }
    return realLength;
}

export function formatAmount(amount) {
    let amountString = cutOutNum(amount, 2);
    let pointIndex = String(amountString).indexOf('.')
    let amountInteger = String(amountString).substring(0, pointIndex)
    let amountPoint = String(amountString).substring(pointIndex + 1, amountString.length)

    let length = amountInteger.length
    if (length <= 3) {
        return amountInteger + '.' + amountPoint;
    }

    let amountStrings = [];
    while (length > 0) {
        let s = amountInteger.substring(length - 3, length);
        amountStrings = amountStrings.concat(s);

        length = length - 3;

    }

    let resAmountString = '';
    for (let i = amountStrings.length - 1; i >= 0; i--) {
        if (i > 0) {
            resAmountString = resAmountString + amountStrings[i] + ',';
        } else {
            resAmountString = resAmountString + amountStrings[i];
        }
    }

    return resAmountString + '.' + amountPoint;

}

export function formatAmountAndInitZero(amount) {
    if (amount) {
        return formatAmount(amount);
    } else {
        return "0.00";
    }
}

export function isIphoneX() {
    const userAgent = window.navigator.userAgent;
    const isIPhone = /iPhone/i.test(userAgent);
    const isIPhoneXSeries = isIPhone && ((window.screen.height === 812 && window.screen.width === 375)
        || (window.screen.height === 896 && window.screen.width === 414)
        || (window.screen.height === 808 && window.screen.width === 414)
        || (window.screen.height === 834 && window.screen.width === 430)
        || (window.screen.height === 724 && window.screen.width === 375));
    return isIPhoneXSeries;
}
export function formattimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要+1
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
}

