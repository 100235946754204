<template>
  <div id="app" ref="div_root">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>

    <div 
  class="service_box" 
  :style="{left: elLeft + 'px', top: elTop + 'px'}"
  draggable="true"
  @touchstart="handleTouchStart"
  @touchmove="handleTouchMove"
  
>
      <img :src="img" alt="" @click="openClienteleServices()">
    </div>
    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>
    <div class="loading_all" v-if="loading2">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<script>
import {post} from "./utils/http";
import Vue from "vue";
import {ACCESS_CustomerUser} from "./store/mutation-types";
import { mapState } from 'vuex';
import {Loading} from "vant";
Vue.use(Loading)


export default {
  name: 'App',
  data() {
    return {
      img: process.env.VUE_APP_ENV=='production'?require("./assets/images/service_box.png"):(process.env.VUE_APP_ENV=='production-zsy'?require("./assets/images/service_boxzsy.png"):require("./assets/images/service_box.png")),
      loading: false,
      defaultUrl: 'https://work.weixin.qq.com/kfid/kfcd3359751cfb7281b',

      initWidth: 0, // 父元素的宽-自适应值
      initHeight: 0, // 父元素的高-自适应值
      startclientX: 0, // 元素拖拽前距离浏览器的X轴位置
      startclientY: 0, //元素拖拽前距离浏览器的Y轴位置
      elLeft: 0, // 元素的左偏移量
      elTop: 0, // 元素的右偏移量
      

    }
  },
  mounted() {
    this.elLeft = this.$refs.div_root.clientWidth - 56;
    this.elTop = 500;
    
  },
  methods: {
    openClienteleServices() {
      this.loading = true;

      let customerUser = Vue.ls.get(ACCESS_CustomerUser);
      if (!customerUser) {
        this.loading = false;
        window.location.href = this.defaultUrl;
        return;
      }

      let businessId = customerUser.businessCustomerUser.business.id

      const url = 'api/business/v2/{businessId}/clientele/services/read'
          .replace('{businessId}', businessId);

      post(url, {}, true).then(res => {
        if (res.data && res.data.clienteleServicesUrl && res.data.clienteleServicesUrl !== '' && res.data.clienteleServicesUrl !== undefined) {
          window.location.href = res.data.clienteleServicesUrl;
        } else {
          window.location.href = this.defaultUrl;
        }

      }).catch(() => {

      }).finally(() => {
        this.loading = false;
      });
    },

    // 拖拽开始事件
    handleTouchStart(e) {
      // e.preventDefault(); 
      this.startclientX = e.changedTouches[0].clientX; // 记录拖拽元素初始位置
      this.startclientY = e.changedTouches[0].clientY;
    },
    handleTouchMove(e) {
    e.preventDefault(); // 阻止默认滚动行为
    let newX = e.touches[0].clientX - 20;
    let newY = e.touches[0].clientY - 20;
    if(newX>330){
      newX=330
    }else if(newX<0){
      newX=0
    }
    if(newY>560){
      newY=560
    }else if(newY<0){
      newY=0
    }
    this.$nextTick(() => {
    this.elLeft =  newX 
    this.elTop = newY 
  });
  
  },
  },
  computed: {
    ...mapState(['loading2'])
  }
}
</script>
<style lang="less" scoped>
.service_box {
  position: fixed;
  width: 40px;
  height: 40px;
  user-select: none;
  z-index: 99999999;
}

.service_box img {
  width: 100%;
  
  z-index: 99999999;
}

</style>